import { AdjustmentsVerticalIcon, ChatBubbleLeftRightIcon, GlobeAltIcon, LockClosedIcon, RocketLaunchIcon, ShieldCheckIcon } from '@heroicons/react/24/outline'

const features = [
    {
        name: 'Versatile Notification Channels',
        description: 'Receive timely alerts through your preferred channels when your websites or servers encounter issues. We support SMS, email, Slack, Discord, webhook notification methods, and notifications through our iOS app.',
        icon: ChatBubbleLeftRightIcon,
    },
    {
        name: 'Informative Status Pages',
        description: 'Display the live status of your websites and servers to your users through our easy-to-set-up status pages. Support for custom domain names with HTTPS encryption is included.',
        icon: LockClosedIcon,
    },
    {
        name: 'Multi-Region Monitoring',
        description: 'Experience rapid issue detection with our premium plans offering 10-second checks from multiple global locations. Get notified quickly when your server experiences downtime.',
        icon: GlobeAltIcon,
    },
    {
        name: 'SSL Certificate Expiration Alerts',
        description: 'Never miss an SSL certificate renewal with our timely alerts. We notify you 30, 14, 7, and 1 day(s) before your certificate expiration.',
        icon: ShieldCheckIcon,
    },
    {
        name: 'Customizable Fail Thresholds',
        description:
            'Define the number of region failures that trigger an alert with our multi-region monitoring feature. Enhance your downtime detection accuracy and minimize false alarms.',
        icon: RocketLaunchIcon,
    },
    {
        name: 'Advanced HTTP(S) Monitoring',
        description: 'Configure custom HTTP headers or payloads for monitoring requests, and check response headers and payloads for thorough website monitoring.',
        icon: AdjustmentsVerticalIcon,
    },
]

export default function FeaturesSection() {
    return (
        <>
            {/* Feature section with grid */}
            <div className="relative bg-white py-16 sm:py-24 lg:py-32" id='features'>
                <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                    {/* <h2 className="text-lg font-semibold text-cyan-600">Features</h2> */}
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        What Uptime Monitor can do?
                    </p>
                    <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
                        Uptime Monitor is a powerful tool for ensuring the availability of your websites and servers. With our service, you can easily keep track of your online assets and receive alerts when there are any issues or disruptions.
                    </p>
                    <div className="mt-12">
                        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                            {features.map((feature) => (
                                <div key={feature.name} className="pt-6">
                                    <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                                        <div className="-mt-6">
                                            <div>
                                                <span className="inline-flex items-center justify-center rounded-md bg-linear-to-r from-teal-500 to-cyan-600 p-3 shadow-lg">
                                                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                                </span>
                                            </div>
                                            <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">{feature.name}</h3>
                                            <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
