import { CheckIcon } from '@heroicons/react/20/solid'
import Link from 'next/link';
import { use, useMemo, useState } from 'react';
import { Subscription, allSubscriptions } from '../../pages/dashboard/billing';

const freePlanFeatures = [
    '10 monitors (up to 3 locations each)',
    '30-second checks',
    'HTTPS, TCP, ping checks support',
    '10 free daily alert emails',
    'Basic status pages',
    '90 days data retention'
];

const proPlanFeatures = [
    '{plan} monitors (up to 16! locations each)',
    '10-second checks',
    'Advanced HTTP(S) monitoring',
    '{plan} free daily alert emails',
    'Unlimited Slack and webhook alerts',
    'Status pages on a custom domain',
    'Optional password protection for status pages',
    '365 days data retention',
]

export default function PricingSection() {
    const [selectedPlan, setSelectedPlan] = useState(10);

    const selectedSubscription: Subscription = useMemo(() => {
        return allSubscriptions.find((sub) => sub.id == selectedPlan.toString()) || allSubscriptions[1];
    }, [selectedPlan]);

    return (
        <div className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl" aria-hidden="true">
                <div
                    className="mx-auto aspect-1155/678 w-[72.1875rem] bg-linear-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
            <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
                {/* <h2 className="text-base font-semibold leading-7 text-indigo-600">Pricing</h2> */}
                <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    Pricing
                </h2>
            </div>
            <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                Experience the speed you deserve with Uptime Monitor. We are proud to offer the industry&apos;s fastest 10-second checks even on our smallest plans.
            </p>
            <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">

                {/* Free plan */}
                <div className='bg-white/60 sm:mx-8 lg:mx-0 rounded-t-3xl sm:rounded-b-none lg:rounded-tr-none lg:rounded-bl-3xl rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10'>
                    <h3 className='text-indigo-600 text-base font-semibold leading-7'>
                        Free
                    </h3>
                    <p className="mt-4 flex items-baseline gap-x-2">
                        <span className='text-gray-900 text-5xl font-bold tracking-tight'>
                            $0
                        </span>
                        <span className='text-gray-500 text-base'>/month</span>
                    </p>
                    <p className='text-gray-600 mt-6 text-base leading-7'>
                        The perfect plan if you&apos;re just getting started.
                    </p>
                    <ul
                        role="list"
                        className='text-gray-600 mt-8 space-y-3 text-sm leading-6 sm:mt-10'
                    >
                        {freePlanFeatures.map((feature) => (
                            <li key={feature} className="flex gap-x-3">
                                <CheckIcon
                                    className='text-indigo-600 h-6 w-5 flex-none'
                                    aria-hidden="true"
                                />
                                {feature}
                            </li>
                        ))}
                    </ul>
                    <Link
                        href='/accounts/create'
                        className='text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline-indigo-600 mt-8 block rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10'
                    >
                        Get started today
                    </Link>
                </div>

                {/* Pro plan */}
                <div className='relative bg-gray-900 shadow-2xl rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10'>
                    <div className='flex items-center justify-between text-white'>
                        <h3 className='text-indigo-400 text-base font-semibold leading-7'>
                            Pro
                        </h3>
                        <div className='flex items-center divide-x divide-slate-500'>
                            {[10, 50, 100, 200, 500, 1000].map(plan =>
                                <button
                                    key={plan}
                                    type="button"
                                    onClick={() => setSelectedPlan(plan)}
                                    className={`${selectedPlan == plan ? 'bg-slate-800 text-lime-300' : 'bg-slate-700 text-gray-300'} ${plan == 10 && 'rounded-l-md'} ${plan == 1000 && 'rounded-r-md'} border-0 px-1.5 py-0.5 text-sm font-medium`}
                                >{plan}</button>
                            )}
                        </div>
                    </div>
                    <p className="mt-4 flex items-baseline gap-x-2">
                        <span className='text-white text-5xl font-bold tracking-tight'>
                            ${selectedSubscription.price / 100}
                        </span>
                        <span className='text-gray-400 text-base'>/month</span>
                    </p>
                    <p className='text-gray-300 mt-6 text-base leading-7'>
                        Maximum precision. Full control.
                    </p>
                    <ul
                        role="list"
                        className='text-gray-300 mt-8 space-y-3 text-sm leading-6 sm:mt-10'
                    >
                        {proPlanFeatures.map((feature) => (
                            <li key={feature} className="flex gap-x-3">
                                <CheckIcon
                                    className='text-indigo-400 h-6 w-5 flex-none'
                                    aria-hidden="true"
                                />
                                {feature.replace('{plan}', selectedPlan.toString())}
                            </li>
                        ))}
                    </ul>
                    <Link
                        href='/accounts/create'
                        className='bg-indigo-500 text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-indigo-500 mt-8 block rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10'
                    >
                        Get started today
                    </Link>
                </div>
            </div>
        </div >
    )
}
